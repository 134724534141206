import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import styles from '../index.css';
import { Box } from '@mui/system';

const AnimFeTurbulence = animated('feTurbulence')
const AnimFeDisplacementMap = animated('feDisplacementMap')

export default function WebApp() {
  const [{ freq, factor, scale, opacity }] = useSpring(
    () => ({
      reverse: false,
      from: { factor: 10, opacity: 0, scale: 0.9, freq: '0.0175, 0.0' },
      to: { factor: 150, opacity: 1, scale: 1, freq: '0.0, 0.0' },
      config: { duration: 3000 },
    }), []
  )

  return (
    <Box sx={{background: "url('../assets/splash_background.svg') no-repeat", backgroundSize: 'cover', height: '100vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Box  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '70%',  }}>
        <animated.svg className={styles.svg} style={{ scale, opacity }} viewBox="0 0 700 280">
          <defs>
            <filter id="water">
              <AnimFeTurbulence type="fractalNoise" baseFrequency={freq} numOctaves="2" result="TURB" seed="8" />
              <AnimFeDisplacementMap xChannelSelector="R" yChannelSelector="G" in="SourceGraphic" in2="TURB" result="DISP" scale={factor} />
            </filter>
          </defs>
          <svg version="1.1" baseProfile="tiny" id="Layer_1">
            <g filter="url(#water)">
              <g>
                <path display="none" fill="#FA982D" d="M107.2,44.3L53.6,62.7L26.7,97.4c0,0-4.9,60.2-4.9,60.4c0,0.3,4.9,46.6,4.9,46.6v26h26.7
                  L64.5,192l75.9,17.4l391.2-1.7l42.4-14.5l85.3,25.1v-88L609.8,142l-39.2-11.6l-10-34.2L537,60.1l-71.5,27.5l-18.2,27l-25.6,11.7
                  l-252.6-6.7l-20.1-6.7l8.6-22.5L107.2,44.3z"/>
                <polygon fill="#FA982D" points="683,130.3 673.3,197.3 25.7,197.3 26.1,173.3 26.1,91.3 66.2,50.3 124.2,49.3 159.3,66.3 
                  160.3,122.3 435,120 458.7,64.3 519.8,48.3 576.9,68.3 558.9,127.3 611.9,130.3 640,130.3 		"/>
                <polygon fill="#FFBE1F" points="110.1,80.3 63.6,87 57,117.3 76.8,121.8 110.1,138.4 128.5,138.4 140.2,121.8 140.2,109.8 		"/>
                <polygon fill="#FFBE1F" points="520.9,80.2 474.5,85.5 467.9,109.6 487.6,113.2 520.9,126.3 539.3,126.3 551,113.2 551,103.7 		
                  "/>
                <path display="none" fill="#FFFFFF" d="M519.4,238.8c-4.3,0-8.8-0.5-13.4-1.5c-4.8-1-9.5-2.8-13.9-5.3c-4.5-2.5-8.6-5.9-12.4-10.1
                  c-2.7-3-5.1-6.6-7.1-10.6v0.5c0,3.6-0.7,7-2,10.2c-1.3,3.1-3.1,5.8-5.4,8.2c-2.3,2.4-5,4.3-8.1,5.7c-3.2,1.4-6.7,2.2-10.3,2.2
                  h-8.4v-13.5c-1.2,2-2.6,3.8-4.2,5.4c-2.3,2.3-5,4.2-8.1,5.6c-3.2,1.4-6.7,2.2-10.4,2.2h-8.4v-18.1c-1,1.1-2.1,2.1-3.2,3.1
                  c-3.3,2.8-7.2,4.9-11.5,6.3c-4.2,1.4-8.8,2.1-13.6,2.1h-0.8l-0.3,0c-5.1-0.4-10.2-2-15.1-4.7c-4.6-2.6-8.7-6-12.3-10.1
                  c-2.6-2.9-4.8-6.2-6.6-9.6v8.9c0,6.4-2.4,12.1-6.9,16.6c-4.5,4.4-10.2,6.7-16.5,6.7h-11.4v-30.9c-0.8,1.2-1.8,2.4-2.8,3.5
                  c-2.6,2.9-5.8,5.1-9.3,6.7c-3.5,1.5-7.3,2.3-11.2,2.3h-1.4l-0.6-0.1c-4.3-0.6-8.2-1.8-11.7-3.6c-0.4,1.8-0.9,3.5-1.7,5.2
                  c-1.4,3.1-3.3,5.8-5.5,8.1c-2.3,2.3-5,4.2-8.1,5.6c-3.2,1.4-6.7,2.2-10.4,2.2h-8.4v-3.9c-0.4,0.2-0.8,0.4-1.2,0.6
                  c-3.8,1.8-8.5,2.8-14.3,3.1l-0.3,0l-0.3,0c-4.3-0.1-8.6-1.1-12.7-2.8c-4.1-1.7-7.8-4.2-11-7.3c-3.2-3.1-5.8-6.8-7.7-11
                  c-2-4.3-3-9.2-3-14.5v-0.3l0-0.3c0.1-1.5,0.3-3.1,0.7-4.8c-6.2-0.6-11.6-3.6-15.7-8.7c-0.4-0.5-0.8-1.1-1.2-1.6v31.6
                  c0,5.4-2,10.3-5.8,14.1c-3.8,3.8-8.7,5.8-14.1,5.8h-14.6v-37.8c-1.6,1.1-3.4,2.1-5.5,3l-0.3,0.1c-4.1,1.7-8.2,2.6-12.2,2.6
                  c-8.9,0-16.3-3.4-21.8-9.8c-3.6,1.5-7.4,2.2-11.3,2.2h-3.3c-2.7,0-5.4-0.6-8.2-1.8c-2.7-1.1-5.4-2.9-8.1-5.3
                  c-1.9-1.7-3.6-3.7-5.1-6.2v27c0,3.6-0.7,7-2,10.2c-1.3,3.1-3.1,5.8-5.4,8.2c-2.3,2.4-5,4.3-8.1,5.7c-3.2,1.4-6.7,2.2-10.3,2.2
                  h-8.4v-91.2h-8.2V122h6.9c0-0.5,0-1,0-1.4c0-5.1,0.6-10.7,1.8-16.4c1.2-6,3.4-12,6.5-17.9c3.1-5.9,7.2-11.6,12.3-16.9
                  C51,63.9,57.5,59,65.1,54.8c11.3-6.3,25.3-9.6,41.4-9.6c10.2,0,19,1.2,26.4,3.6c7.4,2.4,13.7,5.7,18.5,9.5
                  c5.1,4.1,8.9,8.8,11.2,13.9c2.3,5,3.5,10,3.5,14.7c0,0.5,0,1.5-0.2,3.9c-0.1,2.5-0.8,5.1-1.8,7.9c-1.2,3-3.2,5.8-5.8,8.3
                  c-2.9,2.7-6.9,4.5-11.6,5.3l-9.8,1.7v-10.4c0-1.8-0.7-3.8-2.2-6c-1.8-2.7-4.4-5.1-7.7-7.3c-3.5-2.4-7.8-4.3-12.8-5.9
                  c-4.9-1.5-10.2-2.3-15.9-2.3c-5.3,0-10.6,0.9-15.8,2.7c-4.9,1.7-9.3,4.5-13.1,8.1c-3.8,3.7-7,8.5-9.3,14.4
                  c-1.7,4.2-2.8,9.1-3.3,14.6h27.8L85,122c7.9,1,14.7,4.2,20.1,9.4c2.5,2.5,4.7,5.2,6.5,8.1c1.2-0.6,2.4-1.1,3.7-1.6
                  c4.7-1.7,9.6-2.6,14.6-2.6c2.2,0,4.4,0.2,6.6,0.7V122l42.8-0.3v0.2h133.5c1.5-2.1,3.5-3.8,5.8-5.2c3.7-2.2,8.1-3.3,12.9-3.3
                  c0.9,0,1.7,0,2.6,0.1c0.8,0.1,1.7,0.2,2.5,0.3l7.1,1.1v7H437c0-0.5,0-0.9,0-1.4c0-5.2,0.6-10.7,1.8-16.4c1.2-5.9,3.4-12,6.5-17.9
                  c3.1-5.9,7.2-11.6,12.4-16.9c5.1-5.4,11.6-10.3,19.3-14.5c11.4-6.3,25.3-9.6,41.4-9.6c10.2,0,19,1.2,26.3,3.6
                  c7.4,2.4,13.7,5.7,18.5,9.5c5.1,4.1,8.8,8.8,11.2,13.9c2.3,5,3.5,10,3.5,14.7c0,0.5,0,1.5-0.2,3.9c-0.2,2.5-0.8,5.2-1.8,7.9
                  c-1.2,3-3.2,5.8-5.8,8.3c-2.9,2.7-6.9,4.5-11.6,5.3l-9.8,1.7v-10.4c0-1.8-0.7-3.8-2.2-6c-1.8-2.7-4.4-5.1-7.7-7.3
                  c-3.5-2.4-7.8-4.3-12.8-5.9c-4.9-1.5-10.2-2.3-15.9-2.3c-5.3,0-10.6,0.9-15.7,2.7c-5,1.8-9.3,4.4-13.2,8.1
                  c-3.9,3.7-7,8.5-9.3,14.4c-1.7,4.2-2.8,9.1-3.3,14.6h118.4l0.5,0.1c7.9,1,14.7,4.2,20.1,9.4c2.5,2.5,4.7,5.2,6.5,8.1
                  c1.2-0.6,2.4-1.1,3.7-1.6c4.7-1.7,9.6-2.6,14.6-2.6c2.2,0,4.4,0.2,6.6,0.7V122l42.8-0.3v25h-8.2v71c0,5.4-2,10.3-5.8,14.1
                  c-3.9,3.8-8.7,5.8-14.1,5.8h-14.6v-37.8c-1.6,1.1-3.4,2.1-5.5,3l-0.3,0.1c-4.1,1.7-8.2,2.6-12.2,2.6c-8.9,0-16.3-3.4-21.8-9.8
                  c-3.6,1.5-7.4,2.2-11.3,2.2h-3.3c-2.7,0-5.4-0.6-8.3-1.8c-2.7-1.1-5.4-2.9-8.1-5.3c-1.9-1.6-3.5-3.7-5.1-6.2v31.7l-0.4,1.2
                  c-1.2,3.8-3.5,7.2-6.8,10.1c-2.8,2.4-6.2,4.5-10,6.2c-3.7,1.6-7.9,2.8-12.5,3.6C529,238.4,524.3,238.8,519.4,238.8z M366.6,185.1
                  c-0.2,1.2-0.3,2.2-0.3,3.1c0,1.2,0.1,2.8,0.3,4.7c0.1,1.2,0.6,2.5,1.3,3.8c0.6,1.2,1.9,2.6,3.6,4.1c1.7,1.4,4.3,2.6,7.7,3.7
                  c0.5,0.2,0.9,0.2,1,0.2c0.9,0.1,1.7,0.1,2.5,0.2c0.4,0,0.9-0.1,1.4-0.3c0.8-0.3,1.5-0.6,2.1-1c0.4-0.2,0.6-0.5,0.9-0.9
                  c0-0.1,0.1-0.2,0.2-0.6c0-0.2-0.1-0.3-0.2-0.5c-0.2-0.1-0.8-0.2-1.7-0.3c-5.4-0.5-10.1-2.8-13.7-6.7
                  C369.1,191.8,367.4,188.6,366.6,185.1z M528.6,169.1c-3,0.7-5.8,1.4-8.5,2.3c-5.3,1.7-9.7,3.7-13.3,5.9c-3.2,2-5.6,4.1-7.3,6.4
                  c-1.3,1.9-1.9,3.7-1.9,5.7c0,1.3,0.3,2.6,1,3.8c0.8,1.4,1.9,2.6,3.4,3.6c1.7,1.2,3.9,2.2,6.3,3c2.6,0.8,5.5,1.2,8.6,1.2h2.6
                  c1,0,2.8-0.1,5.9-0.5c1-0.1,2-0.3,3.2-0.7L528.6,169.1L528.6,169.1z M472.6,170.7c0.1-0.2,0.3-0.5,0.5-0.8c3.6-6,8.7-11.4,15-16
                  c3.8-2.7,7.9-5.1,12.5-7.2h-28V170.7z M274.3,151.4c6.7,0.4,12.6,1.7,17.5,3.8c5,2.2,9.3,5.1,12.7,8.7c1.6,1.8,3.1,3.6,4.3,5.6
                  v-22.8h-36.5l0,4.6L274.3,151.4z M343.8,165.2c2.3-4.3,5.2-8.2,8.8-11.6c2.8-2.6,6-4.9,9.5-6.9h-18.4L343.8,165.2L343.8,165.2z
                  M171.1,151.6c0.2-0.3,0.4-0.5,0.5-0.8c1-1.4,2.1-2.8,3.4-4.1h-3.9V151.6z M563.2,151c0-0.1,0.1-0.1,0.1-0.2v-4.1h-0.1V151z
                  M60.8,151c0-0.1,0.1-0.1,0.1-0.2v-4.1h-0.1V151z"/>
                <path fill="#5F0018" d="M518.4,53.6c9.3,0,17.2,1.1,23.7,3.2c6.5,2.1,11.8,4.8,15.9,8.1s7,6.9,8.8,10.8c1.8,4,2.7,7.7,2.7,11.2
                  c0,0.6,0,1.8-0.2,3.4c-0.1,1.7-0.5,3.4-1.3,5.3c-0.7,1.9-2,3.6-3.7,5.2s-4.2,2.7-7.4,3.2v-0.5c0-3.5-1.2-7.1-3.6-10.7
                  c-2.4-3.6-5.7-6.8-10-9.6c-4.2-2.8-9.2-5.1-14.9-6.9c-5.7-1.8-11.9-2.6-18.4-2.6c-6.3,0-12.5,1.1-18.5,3.2
                  c-6.1,2.1-11.4,5.4-16.1,9.9c-4.7,4.5-8.5,10.3-11.3,17.3c-2.8,7.1-4.2,15.5-4.2,25.2v0.8h126.5c6.1,0.8,11.2,3.1,15.3,7.1
                  c4.1,4,7,8.8,8.8,14.2c3-2.5,6.4-4.4,10.1-5.8s7.7-2.1,11.7-2.1c5,0,10,1.6,14.9,4.7v-18.1l21.9-0.1c2.3,0,4.1,1.8,4.1,4.1l0,0
                  c0,2.3-1.8,4.1-4.1,4.1h-4.1v79.3c0,3.2-1.1,5.9-3.4,8.2c-2.3,2.2-5,3.4-8.2,3.4h-6.3v-55.1c-1.5-4.6-3.8-8.4-6.9-11.3
                  c-3.1-2.9-6.9-4.4-11.4-4.4c-1.9,0-3.9,0.4-5.9,1.4c-2,0.9-3.8,2.2-5.5,3.7c-1.7,1.6-3,3.4-4,5.4c-1,2-1.5,4.1-1.5,6.4
                  c0,0-0.1,0.4-0.2,1.2c-0.1,0.9,0,1.9,0.5,3c0.4,1.1,1.2,2.1,2.3,3c1.1,0.9,2.8,1.4,5.3,1.4c1.2,0,2.3-0.4,3.5-1.1
                  c1.2-0.7,2.4-2.8,3.5-6.3c0.8-2.5,2.2-4.4,4-5.9c1.8-1.5,4-2.2,6.4-2.2c1.8,0,3.7,0.5,5.5,1.4l0.2,4.2c0,1.6,0,3.3-0.2,5.1
                  c-0.1,1.8-0.6,3.7-1.4,5.5c-0.8,1.9-2.2,3.7-4,5.6c-1.8,1.8-4.3,3.5-7.5,5c-3.1,1.3-6.1,1.9-9,1.9c-8.6,0-14.9-4.2-19.1-12.5
                  c-4.4,3.3-9,5-14,5h-3.3c-1.6,0-3.2-0.4-5.1-1.1c-1.8-0.8-3.7-2-5.7-3.8c-2-1.8-3.8-4.4-5.4-7.8c-0.8-1.8-1.5-3.6-1.8-5.5
                  c-0.4-1.8-0.6-3.5-0.6-5c0-2.3,0.8-4.9,2.2-7.8c1.5-2.9,3.9-6,7.2-9.1l0.3-0.3c2.5,0.7,4.5,2.1,6.1,4c1.6,1.9,2.4,4.2,2.4,6.7
                  c0,1.6-0.5,3.5-1.4,5.6c-1,2.1-1.4,3.9-1.4,5.1c0,0.1,0,0.5,0.2,1.2c0.1,0.7,0.4,1.4,0.9,2.2s1.2,1.5,2.2,2.2c1,0.6,2.3,1,4.2,1
                  c1.9,0,4-1.1,6.3-3.2c2.2-2.2,3.6-5.7,4-10.5c-0.2-5.1-1.9-9.4-4.9-12.8c-3.1-3.4-7.1-5.1-12.3-5.1c-2,0-4.1,0.3-6.1,0.9
                  c-2,0.6-4.1-0.9-4.1-3v-5.3h-16.9v76.8c-0.8,2.3-2.2,4.5-4.3,6.3s-4.8,3.5-7.9,4.8c-3.1,1.3-6.6,2.4-10.6,3.1
                  c-4,0.7-8.2,1.1-12.5,1.1c-3.8,0-7.6-0.4-11.6-1.3c-4-0.9-7.9-2.3-11.6-4.4s-7.1-4.9-10.3-8.4c-3.1-3.5-5.8-7.9-8-13.2
                  c-1.6-3.9-2.4-8-2.4-12.5c0-5.8,1.5-11.2,4.6-16.3c3-5.1,7.3-9.6,12.8-13.5c5.5-3.9,12-7.2,19.4-9.8c7.5-2.6,15.7-4.4,24.6-5.4
                  v-7.2h-72.7v73.4c0,2.5-0.5,4.8-1.4,6.9s-2.1,4-3.7,5.6c-1.6,1.6-3.4,2.9-5.5,3.9c-2.1,1-4.4,1.5-6.9,1.5v-91.2h-13.2v73.1
                  c0,2.5-0.5,4.8-1.4,6.9c-1,2.1-2.3,4-3.9,5.6c-1.6,1.6-3.5,2.9-5.6,3.9c-2.1,1-4.4,1.4-6.9,1.4v-22.8c-0.9-7-2.2-13.1-4.1-18.3
                  c-1.9-5.2-4.1-9.6-6.7-13.2c-2.6-3.6-5.5-6.2-8.6-8c-3.1-1.8-6.3-2.7-9.5-2.7h-1.9c-2.1,0-4.4,0.2-6.7,0.6c-2.3,0.4-4.6,1.1-7,2.3
                  c-2.3,1.2-4.6,2.9-6.7,5.3s-4.1,5.5-5.8,9.5c-0.5,2.4-0.8,4.4-0.8,6.3c0,1.6,0.1,3.5,0.4,5.7c0.3,2.2,1,4.4,2.2,6.7
                  c1.2,2.2,3.1,4.4,5.6,6.6c2.6,2.1,6.1,3.9,10.6,5.3c1.1,0.3,2.1,0.5,3,0.6c0.9,0.1,1.8,0.1,2.7,0.2c1.4,0,2.8-0.2,4.3-0.7
                  c1.5-0.5,2.9-1.1,4.2-2c1.3-0.9,2.3-1.9,3.2-3.3c0.9-1.3,1.4-2.9,1.6-4.6c0-2.5-0.7-4.6-2.2-6.4c-1.4-1.8-3.9-2.9-7.3-3.2
                  c-3.3-0.3-6.1-1.7-8.4-4.1c-2.2-2.4-3.4-5.3-3.4-8.6c3.9-1.5,8.2-2.2,13-2.2c0.2,0,0.7-0.1,1.5-0.2c0.2-0.1,0.5-0.1,0.7-0.1
                  c0.7,0,1.5,0.2,2.5,0.5c1.3,0.4,3,1.4,4.9,2.8c1.9,1.4,4.1,3.8,6.4,7c2.1,4,3.2,7.8,3.2,11.4c0,3.4-0.7,6.6-2.1,9.4
                  c-1.4,2.8-3.4,5.3-5.9,7.4c-2.5,2.1-5.5,3.7-8.8,4.8c-3.4,1.1-7,1.7-11,1.7h-0.5c-4-0.3-7.8-1.6-11.6-3.7
                  c-3.8-2.1-7.2-4.9-10.1-8.3c-2.9-3.4-5.3-7.2-7.2-11.5c-1.8-4.3-2.7-8.8-2.7-13.5l0.2-3.4c0.5-4.3,1.8-8.4,3.7-12.3
                  c1.9-3.9,4.5-7.4,7.7-10.4c3.2-3.1,7-5.5,11.5-7.3c4.4-1.8,9.4-2.7,14.9-2.7c13.2,0,23.5,4.2,31,12.7v-23.9h-80.3v77.1
                  c0,4.2-1.5,7.7-4.4,10.6c-2.9,2.9-6.5,4.4-10.7,4.4h-3v-92h-53.2l-0.1,20.9l10,0.5c5.7,0.3,10.5,1.4,14.6,3.1
                  c4.1,1.8,7.4,4,10,6.7c2.6,2.7,4.5,5.8,5.7,9.2c1.2,3.4,1.8,6.9,1.8,10.4c0,3.4-0.5,6.5-1.6,9.2c-1.1,2.7-2.5,5.1-4.4,7.1
                  c-1.9,2-4,3.6-6.5,4.7c-2.5,1.1-5.1,1.6-7.9,1.6h-0.8c-5.5-0.8-10-2.7-13.5-5.8c-3.5-3.1-5.3-7-5.3-11.8c0-1.9,0.4-3.9,1.2-5.6
                  c0.9-1.9,2.1-3.6,3.6-4.9c1.4-1.3,2.9-2.3,4.5-3.1c1.6-0.8,3.2-1.1,4.7-1.1c3.8,0,7.5,1,11.2,2.9c-0.3,2.5-1.3,4.6-3,6.5
                  c-1.7,1.9-3.7,2.8-6.2,2.8h-1.5c-3.6,0-5.5,1.6-5.5,4.7c0,1.8,0.8,3.5,2.3,5c1.6,1.5,3.7,2.3,6.5,2.3c2.5,0,4.5-0.3,6-0.9
                  c1.5-0.6,2.8-1.4,3.7-2.5c0.9-1.1,1.5-2.3,1.8-3.8s0.5-3,0.5-4.8c0-7.3-2.2-12.5-6.6-15.6c-4.1-2.9-9.5-4.3-16.2-4.3
                  c-0.5,0-1,0-1.4,0l-7.7,0.1v39.2c0,2.5-0.5,4.8-1.4,6.9s-2.2,4-3.9,5.6c-1.6,1.6-3.5,2.9-5.6,3.9s-4.4,1.4-6.9,1.4v-25.1
                  c-4.4-9.5-10.6-14.3-18.7-14.3h-1.3c-1.4,0-3.2,0.2-5.4,0.5c-2.2,0.3-4.4,1-6.5,2.1s-3.9,2.6-5.4,4.5c-1.5,1.9-2.3,4.5-2.3,7.7
                  c0,4.5,1.5,8.4,4.5,11.6c3,3.2,7.5,4.8,13.5,4.8c2,0,4-0.5,6-1.6c1.9-1.1,2.9-2.9,2.9-5.6c0-3-2.3-4.5-7.1-4.5
                  c-2.7-0.2-4.8-1.3-6.4-3.3c-1.6-2-2.5-4.2-2.6-6.8c3.2-1.3,7-1.9,11.4-1.9c1.4,0,3,0.4,4.7,1c1.8,0.7,3.4,1.7,4.8,3
                  c1.5,1.3,2.7,2.8,3.7,4.6c1,1.8,1.5,3.9,1.5,6.1c0,1.3-0.3,2.8-0.8,4.7c-0.5,1.8-1.6,3.6-3.1,5.4c-1.6,1.8-3.8,3.3-6.6,4.7
                  c-2.8,1.3-6.6,2.1-11.2,2.3c-3.3-0.1-6.6-0.8-9.7-2.2c-3.1-1.3-6-3.2-8.4-5.6c-2.5-2.4-4.4-5.2-5.9-8.5c-1.5-3.3-2.2-6.9-2.2-11
                  c0.6-9.2,5.9-16.3,15.7-21.4c1.1-2.2,1.6-5,1.6-8.4c0-0.8-0.1-2.1-0.3-4c-0.2-1.9-0.8-3.9-1.8-5.9c-1-2-2.5-3.7-4.5-5.2
                  c-2-1.5-4.8-2.2-8.4-2.2c-1,0-2.1,0.1-3.5,0.2c-1.3,0.2-2.7,0.6-4.2,1.3c-1.5,0.7-2.8,1.8-4.2,3.3c-1.3,1.5-2.4,3.5-3.3,6.1
                  c-0.6,1.9-0.9,3.7-0.9,5.3c0,0.6,0.1,1.4,0.2,2.3c0.2,0.9,0.5,1.8,1.1,2.6c0.5,0.8,1.2,1.5,2.2,2c0.9,0.6,2.1,0.8,3.6,0.8
                  c2.5,0,4-2,4.5-6.1c0.3-2.5,1.5-4.3,3.5-5.6c2-1.2,4.2-1.8,6.4-1.8h0.6c0.7,2.2,1.1,5,1.1,8.4c0,0.3,0.1,0.9,0.1,1.6
                  c0.1,0.8,0,1.7-0.3,2.8s-1,2.4-2.1,3.8c-1.1,1.4-2.8,2.9-5.1,4.5c-2.6,1.3-5,1.9-7.4,1.9c-4.7,0-8.6-1.9-11.7-5.7
                  c-3.1-3.8-4.7-8.6-4.7-14.5c0-1.9,0.4-4,1.1-6.1c0.8-2.1,1.8-4.2,3.2-6.2c1.4-2,3.1-3.8,5.1-5.5c2-1.7,4.3-3,6.9-4.1
                  c-2.6-3.6-6.3-6.2-11.1-7.7h-16.7v79.3c0,3.2-1.1,5.9-3.4,8.2c-2.2,2.2-5,3.4-8.2,3.4h-6.3v-55.1c-1.5-4.6-3.8-8.4-6.9-11.3
                  c-3.1-2.9-6.9-4.4-11.4-4.4c-1.9,0-3.9,0.4-5.9,1.4c-2,0.9-3.8,2.2-5.4,3.7c-1.7,1.6-3,3.4-4,5.4c-1,2-1.5,4.1-1.5,6.4
                  c0,0-0.1,0.4-0.2,1.2c-0.1,0.9,0.1,1.9,0.5,3c0.4,1.1,1.2,2.1,2.2,3c1.1,0.9,2.8,1.4,5.3,1.4c1.2,0,2.4-0.4,3.5-1.1
                  c1.2-0.7,2.4-2.8,3.6-6.3c0.8-2.5,2.2-4.4,4-5.9c1.8-1.5,3.9-2.2,6.4-2.2c1.8,0,3.6,0.5,5.4,1.4l0.2,4.2c0,1.6-0.1,3.3-0.2,5.1
                  c-0.1,1.8-0.6,3.7-1.4,5.5c-0.9,1.9-2.2,3.7-4,5.6c-1.8,1.8-4.4,3.5-7.6,5c-3.1,1.3-6.1,1.9-9,1.9c-8.6,0-14.9-4.2-19.1-12.5
                  c-4.4,3.3-9,5-14,5h-3.3c-1.5,0-3.2-0.4-5.1-1.1s-3.7-2-5.7-3.8c-2-1.8-3.8-4.4-5.4-7.8c-0.9-1.8-1.5-3.6-1.9-5.5
                  c-0.4-1.8-0.5-3.5-0.5-5c0-2.3,0.7-4.9,2.2-7.8c1.5-2.9,3.9-6,7.2-9.1l0.3-0.3c2.5,0.7,4.5,2.1,6.1,4c1.6,1.9,2.4,4.2,2.4,6.7
                  c0,1.6-0.5,3.5-1.4,5.6c-1,2.1-1.5,3.9-1.5,5.1c0,0.1,0.1,0.5,0.2,1.2c0.1,0.7,0.4,1.4,0.9,2.2c0.5,0.8,1.2,1.5,2.2,2.2
                  c1,0.6,2.4,1,4.2,1c1.9,0,4-1.1,6.3-3.2c2.3-2.2,3.6-5.7,4-10.5c-0.2-5.1-1.8-9.4-4.9-12.8c-3.1-3.4-7.1-5.1-12.3-5.1
                  c-2.2,0-4.5,0.4-6.8,1.1c-1.7,0.6-3.5-0.7-3.5-2.5v-6H52.4v73.4c0,2.5-0.5,4.8-1.4,6.9c-0.9,2.1-2.1,4-3.7,5.6
                  c-1.6,1.6-3.4,2.9-5.5,3.9c-2.1,1-4.5,1.5-6.9,1.5v-91.2h-4.4c-2.1,0-3.8-1.7-3.8-3.8l0,0c0-2.4,1.9-4.3,4.3-4.3h3.4
                  c-0.2-1.7-0.4-3.4-0.5-5s-0.2-3.2-0.2-4.8c0-4.6,0.5-9.5,1.6-14.8s3-10.5,5.7-15.7c2.7-5.2,6.4-10.2,11-15
                  c4.6-4.8,10.4-9.2,17.3-13c10.2-5.7,22.6-8.5,37.4-8.5c9.3,0,17.2,1.1,23.7,3.2c6.5,2.1,11.8,4.8,15.9,8.1c4.1,3.3,7,6.9,8.8,10.8
                  c1.8,4,2.7,7.7,2.7,11.2c0,0.6-0.1,1.8-0.1,3.4c-0.1,1.7-0.5,3.4-1.3,5.3c-0.7,1.9-2,3.6-3.7,5.2s-4.2,2.7-7.4,3.2v-0.5
                  c0-3.5-1.2-7.1-3.6-10.7c-2.4-3.6-5.7-6.8-9.9-9.6c-4.2-2.8-9.2-5.1-14.9-6.9c-5.7-1.8-11.9-2.6-18.4-2.6
                  c-6.3,0-12.5,1.1-18.5,3.2c-6,2.1-11.4,5.4-16.1,9.9c-4.7,4.5-8.5,10.3-11.3,17.3c-2.8,7.1-4.2,15.5-4.2,25.2v0.8h36
                  c6.1,0.8,11.2,3.1,15.3,7.1c4.1,4,7,8.8,8.8,14.2c3-2.5,6.4-4.4,10.1-5.8s7.7-2.1,11.7-2.1c5,0,10,1.6,14.9,4.7v-18.1l26-0.2v0.2
                  h147c0.9-2.8,2.5-4.9,5-6.3c2.5-1.5,5.4-2.2,8.7-2.2c0.6,0,1.3,0,1.9,0.1c0.6,0.1,1.3,0.1,1.9,0.2v8.2h110.8
                  c-0.2-1.7-0.4-3.4-0.6-5s-0.2-3.2-0.2-4.8c0-4.6,0.5-9.5,1.6-14.8s3-10.5,5.7-15.7c2.7-5.2,6.4-10.2,11-15
                  c4.6-4.8,10.4-9.2,17.3-13C491.2,56.4,503.6,53.6,518.4,53.6 M246.2,157.8v-19.4H236c-0.6,1.8-0.9,3.5-0.9,5.1v0.6
                  C235.9,151.2,239.7,155.8,246.2,157.8 M225.4,177.2h1.8c7.5,0,13.9,1.7,19.1,5.1v-11.2c-3.6-1.1-6.8-2.5-9.6-4.3
                  c-2.7-1.8-5-3.9-6.8-6.2c-1.8-2.3-3.2-4.8-4.1-7.4c-0.9-2.6-1.4-5.3-1.4-8.1c0-2.4,0.3-4.6,0.8-6.7h-27l0.8,0.9
                  c0.8,0.9,1.4,1.8,1.9,2.7c0.5,0.9,0.9,1.8,1.4,2.6l1.3,0.2c2.9,0.5,5.7,1.5,8.3,3c2.6,1.5,5,3.4,7,5.7c2.1,2.4,3.7,5.2,4.9,8.4
                  c1.2,3.3,1.8,6.9,1.8,11L225.4,177.2 M516.9,209.4h2.6c1.7,0,4-0.2,7-0.6c2.9-0.4,6.4-1.4,10.5-3v-46.7c-7.1,1-13.5,2.4-19.4,4.3
                  c-5.9,1.9-10.9,4.2-15.1,6.8c-4.2,2.6-7.4,5.5-9.7,8.8c-2.3,3.3-3.5,6.8-3.5,10.5c0,2.8,0.7,5.4,2.1,7.9c1.4,2.5,3.3,4.6,5.8,6.3
                  c2.5,1.8,5.4,3.2,8.8,4.2C509.2,208.9,512.9,209.4,516.9,209.4 M518.4,36.8c-17.6,0-32.9,3.6-45.5,10.6
                  c-8.4,4.7-15.6,10.1-21.3,16.1c-5.6,5.9-10.2,12.2-13.7,18.8c-3.5,6.6-5.9,13.4-7.3,20.1c-0.8,3.8-1.3,7.6-1.6,11.2h-76.9v-5.8
                  l-14.2-2.2c-1.2-0.2-2.2-0.3-3.2-0.4c-1.1-0.1-2.2-0.1-3.2-0.1c-6.3,0-12.1,1.5-17.1,4.5c-2,1.2-3.8,2.5-5.3,4l-145.4-0.1
                  c0.1-0.1,0.2-0.2,0.3-0.2c3.6-3.3,6.3-7.2,8-11.4c1.4-3.5,2.2-7,2.4-10.4c0.2-2.7,0.2-3.8,0.2-4.5c0-6-1.4-12.1-4.3-18.2
                  c-2.9-6.3-7.4-12-13.6-16.9c-5.6-4.5-12.7-8.2-21.1-11c-8.2-2.7-17.9-4-29-4C89,36.9,73.7,40.4,61,47.5
                  c-8.4,4.6-15.5,10-21.3,16.1c-5.6,5.9-10.2,12.2-13.7,18.8c-3.5,6.6-5.9,13.4-7.3,20.1c-0.8,3.8-1.3,7.6-1.6,11.2l0,0
                  c-4,0-7.2,3.2-7.2,7.2v9.5v8v9.5c0,4,3.2,7.2,7.2,7.2h1v74.5v16.7h16.7c4.8,0,9.5-1,13.8-2.9c4.1-1.8,7.7-4.3,10.7-7.5
                  c3-3.1,5.4-6.7,7.1-10.8c1.8-4.2,2.7-8.7,2.7-13.4v-8.5c0.5,0.3,1.1,0.5,1.6,0.7c3.8,1.6,7.7,2.4,11.4,2.4h3.3
                  c3.1,0,6.1-0.4,9-1.1c6.6,5.6,14.8,8.6,24.1,8.6c3.2,0,6.4-0.4,9.6-1.2v16.6V246h16.7h6.3c7.7,0,14.6-2.9,20-8.3
                  c5.4-5.4,8.3-12.3,8.3-20V209c0.6,3.8,1.7,7.5,3.2,10.9c2.3,5.1,5.5,9.6,9.4,13.5c4,3.9,8.6,6.9,13.6,9.1
                  c5.1,2.2,10.4,3.3,15.8,3.5l24.8,0.2c4.9,0,9.5-1,13.8-2.9c4-1.8,7.5-4.2,10.5-7.3c2.5-2.5,4.5-5.3,6.2-8.4
                  c1.8,0.5,3.7,0.9,5.7,1.1l1.1,0.2h1.1h0.8c5.1,0,10-1,14.5-3c0.1-0.1,0.2-0.1,0.4-0.2v4.9v16.7h16.7h3c8.6,0,16.3-3.2,22.4-9.1
                  c3.1-3.1,5.5-6.6,7.1-10.5c2.7,2.3,5.6,4.4,8.7,6.1c6,3.3,12.2,5.3,18.5,5.8l0.7,0.1h0.7h0.5c5.7,0,11.2-0.9,16.3-2.5
                  c1.3-0.4,2.6-0.9,3.8-1.4V246h16.7c4.9,0,9.5-1,13.8-2.9c0.1-0.1,0.3-0.1,0.5-0.2v3.5h16.7c4.8,0,9.5-1,13.8-2.9
                  c4.1-1.8,7.7-4.3,10.7-7.5c1.8-1.8,3.3-3.9,4.7-6c3.8,3.8,7.8,6.9,12.2,9.4c5.2,2.9,10.7,5,16.3,6.2c5.2,1.1,10.2,1.6,15.1,1.6
                  c5.3,0,10.5-0.4,15.5-1.3c5.1-0.9,10-2.3,14.3-4.1c4.7-2,8.8-4.6,12.3-7.6c4.4-3.9,7.5-8.5,9.2-13.8l0.8-2.5v-2.6v-11.9
                  c0.5,0.2,1,0.5,1.6,0.7c3.9,1.6,7.7,2.4,11.5,2.4h3.3c3.1,0,6.1-0.4,9-1.1c6.5,5.6,14.8,8.6,24,8.6c3.2,0,6.4-0.4,9.6-1.2v16.6
                  V246h16.7h6.3c7.7,0,14.6-2.8,20-8.2l0,0l0,0c5.4-5.4,8.2-12.3,8.2-20v-62.6l0,0c4.5,0,8.2-3.7,8.2-8.2v-8.5v-8.2v-8.6
                  c0-4.6-3.7-8.2-8.2-8.2l-8.6,0.1l-26,0.2l-8.9,0.1c-4.3,0-7.7,3.5-7.7,7.8v5.7c-4.6,0.2-9.2,1-13.5,2.3c-1.1-1.4-2.3-2.7-3.6-3.9
                  c-6.8-6.6-15.1-10.6-24.9-11.8l-1-0.1h-1h-11.1c0.1-0.1,0.3-0.2,0.4-0.4c3.6-3.3,6.3-7.2,8-11.4c1.4-3.5,2.2-7,2.4-10.4
                  c0.1-2.1,0.2-3.4,0.2-4.5c0-6-1.4-12.1-4.3-18.2c-2.9-6.3-7.4-12-13.5-16.9c-5.6-4.5-12.7-8.2-21.1-11
                  C539.2,38.2,529.4,36.8,518.4,36.8L518.4,36.8z M478.6,113.6c0.3-1.1,0.7-2.1,1-3c1.9-4.8,4.4-8.7,7.4-11.5
                  c3-2.9,6.3-4.9,10.2-6.3c4.2-1.5,8.6-2.3,13-2.3c4.9,0,9.3,0.6,13.5,1.9c4.2,1.3,7.7,2.9,10.5,4.8c2.4,1.6,4.2,3.3,5.4,5.1
                  c0.6,0.9,0.7,1.3,0.8,1.5v0.4v9.4L478.6,113.6L478.6,113.6z M66.8,113.6c0.3-1.1,0.7-2.1,1.1-3c1.9-4.8,4.4-8.7,7.4-11.5
                  c3-2.9,6.3-4.9,10.2-6.3c4.3-1.5,8.5-2.2,13-2.2c4.9,0,9.3,0.6,13.5,1.9c4.2,1.3,7.7,2.9,10.5,4.8c2.4,1.6,4.2,3.3,5.4,5l0,0l0,0
                  c0.6,0.8,0.7,1.3,0.8,1.5v0.3l-0.3,23c-4.6,0.2-9.2,0.9-13.5,2.3c-1.1-1.4-2.3-2.7-3.6-3.9c-6.8-6.6-15.1-10.6-24.9-11.8l-1-0.1
                  h-1L66.8,113.6L66.8,113.6z M506,189.3c0-0.1,0.1-0.3,0.4-0.6c1-1.4,2.6-2.8,4.8-4.1c2.5-1.5,5.5-2.9,9-4.3v12.4
                  c-0.4,0-0.6,0-0.8,0h-2.6c-2.3,0-4.4-0.3-6.2-0.8c-1.6-0.5-2.9-1.1-3.8-1.7l0,0l0,0C506.4,189.7,506.1,189.5,506,189.3L506,189.3z
                  "/>
              </g>
            </g>
          </svg>
        </animated.svg>
        </Box>
    </Box>
  )
}
