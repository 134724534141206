import React from 'react'
import { Box, Grid, Typography } from "@mui/material";


export default function MobileApps() {
    const cons = [
        {
            color: '#00796b',
            link: 'https://play.google.com/store/apps/details?id=com.shikkhanobish.attendance',
            title: 'শিক্ষানবিশ শিক্ষার্থী হাজিরা',
            desc: 'শিক্ষাপ্রতিষ্ঠানের শিক্ষার্থীদের দৈনন্দিন হাজিরা দাখিলের জন্য এবং সহজে যাচাই-বাছাই এর জন্য শিক্ষানবিশ শিক্ষার্থী হাজিরা মোবাইল অ্যাপ শ্রেণীশিক্ষকগণের কাজ আয়াসসাধ্য করে তুলেছে।'
        },
        {
            color: '#25525c',
            link: 'https://play.google.com/store/apps/details?id=com.shikkhanobish.students',
            title: 'শিক্ষানবিশ শিক্ষার্থী',
            desc: 'শিক্ষানবিশ শিক্ষার্থী অ্যাপ এর মাধ্যমে শিক্ষার্থীরা সহজে তথ্য প্রেরণ, পরিবর্তন ও সংরক্ষণ করতে পারে। যা শিক্ষাপ্রতিষ্ঠানের সাথে শিক্ষার্থীদের একটি সহজ ও সাবলীল বন্ধন সেতু তৈরি করে।'
        },
        
    ]
    return (
        <>
        {
            cons.map((item, index) => (
                <Box  sx={{ backgroundColor: item.color, padding: '25px 0 0 0', minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
                    <Grid container sx={{ flexDirection: { sx: 'column', md: index % 2 === 0 ? 'row-reverse' : 'row'}, padding: '30px 0', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid xs={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ maxWidth: 450, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <a href={item.link}><img width={250} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid xs={12} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', margin: '22px' }}>
                                <Box sx={{ maxWidth: 620, textAlign: index % 2 === 0 ? 'right': 'left'}}>
                                    <Typography variant="h3" component="h3" sx={{ fontFamily: 'Hind Siliguri', color: 'white', fontWeight: 'bold'}}>{item.title}</Typography>
                                    <Typography variant="h6" component="h6" sx={{ fontFamily: 'Hind Siliguri', marginY: '12px', color: '#ededed', fontWeight: 'medium'}}>{item.desc}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ))
        }
        </>
        
    )
}