import { React, useState } from 'react';
import { Box, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions , TextField, InputAdornment, Snackbar, Alert } from "@mui/material";
import Lottie from "lottie-react";
import animation from '../lotties/tryapp_animation.json';
import axios from 'axios';
import lodash from 'lodash';
export default function TryApp() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [calltime, setCalltime] = useState(new Date().toISOString().substring(0, 19));
    const [modal, setModal] = useState(false);
    const [captcha, setCaptcha] = useState([lodash.random(1, 20), lodash.random(1, 9)]);
    const [captchaResult, setCaptchaResult] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');

    const submitCallRequest = () => {
        if(name.trim().length === 0 || /^(?:\+88|88)?(01[3-9]\d{8})$/g.test(phone.trim()) === false || calltime.trim().length === 0) {
            setAlertType('warning')
            setAlertMessage('সকল তথ্য সঠিক ভাবে প্রদান করুন');
            setOpenSnackbar(true);
        } else if (lodash.parseInt(captchaResult) === lodash.sum(captcha)) {
            axios.get('/api/phonecallrequest?name='+name.trim()+'&phone='+phone.trim()+'&calltime='+calltime)
            .then((res) => {
                if(res.data.error === false) {
                    setAlertType('success')
                    setAlertMessage(res.data.message);
                    setOpenSnackbar(true);
                    setModal(false);
                } else {
                    setAlertType('error')
                    setAlertMessage(res.data.message);
                    setOpenSnackbar(true);
                }
            })
            .catch((err) => {
                setAlertType('error')
                setAlertMessage('কোনো একটি সমস্যা হয়েছে। আবার চেষ্টা করুন।');
                setOpenSnackbar(true);
            })
        } else {
            setAlertType('error')
            setAlertMessage('কোনো একটি সমস্যা হয়েছে। আবার চেষ্টা করুন।');
            setOpenSnackbar(true);
        }
    }
    return (
        <Box sx={{ backgroundColor: '#01579b', padding: '25px 0 0 0', minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
            <Grid container direction="row" sx={{ padding: '30px 0', alignItems: 'center' }}>
                <Grid xs={12} md={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: 450, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            <Lottie animationData={animation} loop={true} />
                        </Box>
                    </Box>
                </Grid>

                <Grid xs={12} md={6}>
                    <Box sx={{ maxWidth: 620, display: 'flex', alignItems: 'center', flexWrap: 'wrap', margin: '22px' }} >
                        <Typography variant="h3" component="h3" sx={{ fontFamily: 'Hind Siliguri', color: 'white', fontWeight: 'bold'}}>শিক্ষাপ্রতিষ্ঠান নিয়ন্ত্রন হোক সবচেয়ে সহজে</Typography>
                        <Typography variant="h6" component="h6" sx={{ fontFamily: 'Hind Siliguri', marginY: '12px', color: '#ededed', fontWeight: 'medium'}}>শিক্ষানবিশ এমন একটি স্বয়ংক্রিয় ব্যাবস্থা যা একটি শিক্ষাপ্রতিষ্ঠানকে সম্পূর্ণরুপে নিয়ন্ত্রনের সুবিধা দিয়ে থাকে। গতানুগতিক শিক্ষামাধ্যমকে আধুনিকায়নের লক্ষ্যেই শিক্ষানবিশের পথচলা।</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end', marginY: '8px' }}>
                            <Button href="/signin" sx={{ padding: '10px', borderRadius: '4px', background: 'white', width: '120px', fontFamily: 'Hind Siliguri', fontSize: '20px', fontWeight: 'bold', '&:hover': {backgroundColor: '#FE993D', color: 'white'} }}>লগ ইন</Button>
                            <Button sx={{ padding: '10px', marginX: '4px', borderRadius: '4px', color: 'white', width: '120px', fontFamily: 'Hind Siliguri', fontSize: '20px', fontWeight: 'bold' }} onClick={ () => setModal(true)}>বিস্তারিত</Button>
                        </Box>
                  </Box>
                </Grid>
            </Grid>

            <Dialog open={modal} onClose={() => {setModal(false); setCaptcha([lodash.random(1, 20), lodash.random(1, 9)]); setCaptchaResult(0); setName(''); setPhone(''); setCalltime(new Date().toISOString().substring(0, 19))}}>
            <DialogTitle sx={{ fontFamily: 'Hind Siliguri', fontWeight: 'bold'}}>ফোন কল রিকোয়েস্ট</DialogTitle>
                <DialogContent dividers>
                    <TextField fullWidth id="new_client_name" label="আপনার নাম" variant="outlined" sx={{ marginY: '6px' }} onInput={(e)=> setName(e.target.value)} />
                    <TextField fullWidth id="new_client_phone" label="ফোন নম্বর" variant="outlined" sx={{ marginY: '6px' }} onInput={(e)=> setPhone(e.target.value)} InputProps={{ startAdornment: <InputAdornment position="start">+88</InputAdornment>, }}/>
                    <TextField fullWidth id="new_client_time" focused label="কল করার তারিখ ও সময়" variant="outlined" type="datetime-local" defaultValue={calltime} onInput={(e)=> setCalltime(e.target.value)} sx={{ marginY: '6px' }}/>
                    <TextField fullWidth id="new_client_captcha" label="সমাধান" variant="outlined" sx={{ marginY: '6px' }} InputProps={{ startAdornment: <InputAdornment position="start">{captcha[0]} + {captcha[1]} = </InputAdornment>, }} onInput={(e) => setCaptchaResult(e.target.value)} />
                </DialogContent>
                <DialogActions>
                <Button variant="contained" disableElevation onClick={submitCallRequest} sx={{ fontFamily: 'Hind Siliguri' }} >
                    রিকোয়েস্ট
                </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            
      </Box>
    )
    }