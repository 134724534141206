import './App.css';
import { useState, useEffect } from 'react';
import  WebBar  from './viewes/webbar';
import TryApp from './viewes//tryapp';
import Website from './viewes/modules';
import MobileApps from './viewes/mobileapps';
import Footer from './viewes/footer';
import { Scrollbars } from 'react-custom-scrollbars-2';
function App() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return (
    <>
      <Scrollbars style={{ height: windowSize[1] }}>
        <WebBar/>
        <TryApp />
        <Website />
        <MobileApps />
        <Footer />
      </Scrollbars>
    </>
  );
}

export default App;
