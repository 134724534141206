import React from 'react'
import { Box, Grid, Typography } from "@mui/material";
import Lottie from "lottie-react";
import website_animation from '../lotties/website_animation.json';
import card_punch_animation from '../lotties/card_punch_animation.json';
import student_animation from '../lotties/student_animation.json';
import payment_animation from '../lotties/payment_animation.json';
import teacher_animation from '../lotties/teacher_animation.json';
import rps_animation from '../lotties/rps_animation.json';
import admission_animation from '../lotties/admission_animation.json';

export default function Modules() {
    const cons = [
        {
            color: '#00796b',
            animation: website_animation,
            title: 'ডায়নামিক ওয়েব পোর্টাল',
            desc: 'শিক্ষাপ্রতিষ্ঠানের জন্য শিক্ষানবিশ সম্পূর্ণ ডায়নামিক ওয়েবসাইট প্রদান করে। যা শিক্ষাপ্রতিষ্ঠানের সকল কার্যক্রম বিশ্বদ্বারে সহজে উপস্থাপনের একটি সুগম পথ।'
        },
        {   
            color: '#2B4865',
            animation: card_punch_animation,
            title: 'ডিজিটাল হাজিরা',
            desc: 'গতানুগতিক হাজিরা পদ্ধতিকে পিছনে ফেলে শিক্ষানবিশ শিক্ষার্থী, শিক্ষকসহ সকলের জন্য যন্ত্র ও অ্যাপ নির্ভর হাজিরা ব্যাবস্থার মাধ্যমে সকলের নিয়ন্ত্রিত উপস্থিতি নিশ্চিত করছে।'
        },
        {   
            color: '#3D5656',
            animation: student_animation,
            title: 'শিক্ষার্থী নথি',
            desc: 'শিক্ষার্থীদের সকল তথ্য নিরাপদ ও সহজে সংরক্ষণ, অনুসন্ধান, নিয়ন্ত্রণ, বাতিল ইত্যাদি সুবিধা নিয়ে শিক্ষানবিশ শিক্ষার্থী নথি সিস্টেম প্রদান করে। যা প্রতিষ্ঠানের শিক্ষার্থী বিষয়ক সকল সমস্যার নির্ভুল ও সুন্দর সমাধান।'
        },
        {   
            color: '#293462',
            animation: teacher_animation,
            title: 'শিক্ষক নথি',
            desc: 'শিক্ষক নথি সিস্টেমের মাধ্যমে শিক্ষানবিশ শিক্ষাপ্রতিষ্ঠানকে শিক্ষক-শিক্ষিকা সহ অধিভুক্ত সকল কর্মকর্তাদিগের সকল তথ্যাদি নিবন্ধন, পরিচালনা করতে নিয়ন্ত্রন প্রদান করে। যার মাধ্যমে শিক্ষাপ্রতিষ্ঠানের কাঠামো সংরক্ষণ সহজতর হয়।'
        },
        {   
            color: '#33691e',
            animation: payment_animation,
            title: 'অনলাইন পেমেন্ট',
            desc: 'শিক্ষাপ্রতিষ্ঠানের বেতন সহ সকল ধরনের পেমেন্ট গ্রহনের জন্য শিক্ষানবিশ অনলাইন পেমেন্ট সিস্টেম সুবিধা দান করে। যার প্রেক্ষিতে প্রতিষ্ঠান দ্রুততম সময়ে, সহজে, নির্ভুলভাবে এবং নিরাপদে সকল পেমেন্ট গ্রহন করতে সক্ষম।'
        },
        {   
            color: '#3C2A21',
            animation: rps_animation,
            title: 'পরীক্ষার ফলাফল প্রক্রিয়াকরণ',
            desc: 'শিক্ষাপ্রতিষ্ঠানের সকলধরনের পরীক্ষার ফলাফলের নম্বর উত্তোলন, বিন্যাসসহ মার্কশিট, টেবুলেশন ইত্যাদি সহজে প্রক্রিয়াকরন, প্রকাশের নির্ভুল সুবিধা প্রদান করে শিক্ষানবিশ। যা পরীক্ষার ফলাফল প্রকাশজনিত জটিলতাকে সহজ করে তোলে।'
        },
        {   
            color: '#1C315E',
            animation: admission_animation,
            title: 'ভর্তি আবেদন',
            desc: 'শিক্ষাপ্রতিষ্ঠানে ভর্তি প্রক্রিয়াকে অনলাইনভিত্তিক করার সুবিধা নিয়ে শিক্ষানবিশ সকল প্রকার জটিলতাকে নিরসন করে। যা ব্যবহারের মাধ্যমে প্রতিষ্ঠান সহজে ভর্তি ফরম বিতরণ, গ্রহন, ভর্তি-ফি গ্রহণ, বাছাই ইত্যাদি সুবিধা পেয়ে থাকে।'
        },
    ]
    return (
        <>
        {
            cons.map((item, index) => (
                <Box  sx={{ backgroundColor: item.color, padding: '25px 0 0 0', minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
                    <Grid container sx={{ flexDirection: { sx: 'column', md: index % 2 === 0 ? 'row-reverse' : 'row'}, padding: '30px 0', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid xs={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ maxWidth: 450, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <Lottie animationData={item.animation} loop={true} />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid xs={12} md={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', margin: '22px' }}>
                                <Box sx={{ maxWidth: 620, textAlign: index % 2 === 0 ? 'right': 'left'}}>
                                    <Typography variant="h3" component="h3" sx={{ fontFamily: 'Hind Siliguri', color: 'white', fontWeight: 'bold'}}>{item.title}</Typography>
                                    <Typography variant="h6" component="h6" sx={{ fontFamily: 'Hind Siliguri', marginY: '12px', color: '#ededed', fontWeight: 'medium'}}>{item.desc}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ))
        }
        </>
        
    )
}