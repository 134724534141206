import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import styles from '../index.css';
import { Box } from '@mui/system';

export default function Footer() {
    return(
        <>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 2, background: '#0b1120', fontFamily: 'Hind Siliguri', color: 'grey' }}>
            <Box>
                কপিরাইট ©{new Date().getFullYear()} শিক্ষানবিশ। সর্বস্বত্ব সংরক্ষিত।
            </Box>
            <Box>
                প্রাইভেসি পলিসি
            </Box>
        </Box>
        </>
    )
}